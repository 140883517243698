import { Flex } from '@opsdti-global-component-library/amgen-design-system';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHandleSubscription } from '../../../hooks/use-handle-subscription';
import { useValidationModal } from '../../../hooks/use-validation-modal';
import { AppContext } from '../../../state/app-context';
import {
	DelegateList,
	DelegateObject,
	FetchNotificationResponse,
	FilterSetting,
	SubscriptionAction,
	SubscriptionType,
	UserType,
} from '../../../types';
import { getUniqueValues } from '../../../utils/get-unique-values';
import FilterComponent from '../../filter-component';
import PreviewModal from '../../modals/preview-modal';
import ValidationModal from '../../modals/validation-modal';
import NotificationsLoader from '../../reusable/notifications-loader';
import NotificationsTable from '../../tables/notifications-table';
import '../index.scss';

interface ManageSubscriptionsTabContentProps {
	data: Array<FetchNotificationResponse>;
	length?: number;
	type: SubscriptionType;
	filter: FilterSetting;
	delegates: DelegateList;
	tableLoader: boolean;
	setFilter: (setting: FilterSetting) => void;
}

const ManageSubscriptionsTabContent = ({
	data,
	type,
	filter,
	delegates,
	tableLoader,
	setFilter,
}: ManageSubscriptionsTabContentProps) => {
	const {
		userFullname,
		selectedSubscriber,
		isRefetchingNotifications,
		tableContentLoader,
		setTableContentLoader,
		setOriginalList,
		setEnablePolling,
		setSelectedSubscriber,
	} = useContext(AppContext);

	const [notificationData, setNotificationData] = useState<
		FetchNotificationResponse | undefined
	>(undefined);
	const [openPreview, setOpenPreview] = useState(false);
	const { validationModalConfig, openValidationModal, closeValidationModal } =
		useValidationModal();
	const [uniquePages, setUniquePages] = useState<
		Array<{ value: string; label: string }>
	>([]);
	const [uniqueTriggers, setUniqueTriggers] = useState<
		Array<{ value: string; label: string }>
	>([]);
	const [filterPivot, setFilterPivot] = useState<string | undefined>(undefined);

	const previewModalDismissed = useCallback(() => {
		setOpenPreview(false);
	}, []);

	const mutation = useHandleSubscription(
		type === SubscriptionType.SUBSCRIBED
			? 'SubscriptionList'
			: 'NotificationList'
	);
	const handleSubscription = (
		action: SubscriptionAction,
		notificationUpdates: any
	) => {
		setTableContentLoader(true);
		previewModalDismissed();

		const updatedNotificationData: FetchNotificationResponse = {
			...notificationUpdates,
		};

		mutation.mutate({
			data: {
				is_admin: false,
				user_full_name: userFullname,
				notifications: [
					{
						notification_id: updatedNotificationData?.id ?? '',
						user_frequency: updatedNotificationData?.frequency ?? '',
						delivery_channel: updatedNotificationData.selected_channels ?? [],
						is_enabled: updatedNotificationData.is_enabled ?? false,
						action_type: action,
					},
				],
				is_delegatee: selectedSubscriber.is_delegatee,
				delegate_user_id:
					updatedNotificationData.is_ad_group &&
					updatedNotificationData.ad_group
						? updatedNotificationData.ad_group
						: selectedSubscriber.delegate_user_id,
				delegate_type: updatedNotificationData.is_ad_group
					? UserType.AD
					: UserType.USER,
			},
			notification: updatedNotificationData,
		});
	};

	const updatePageFilter = useCallback(() => {
		const uniquePagesCopy: Array<{ value: string; label: string }> =
			getUniqueValues(data, 'workstream')
				.sort()
				.map((page) => {
					return { value: page, label: page };
				});
		if (uniquePagesCopy.length > 1) {
			uniquePagesCopy.unshift({ value: 'All', label: 'All' });
		}
		setUniquePages([...uniquePagesCopy]);
	}, [data, setUniquePages]);

	const updateTriggerFilter = useCallback(() => {
		const uniqueTriggersCopy: Array<{ value: string; label: string }> =
			getUniqueValues(data, 'arche_type')
				.sort()
				.map((trigger) => {
					return { value: trigger, label: trigger };
				});
		if (uniqueTriggersCopy.length > 1) {
			uniqueTriggersCopy.unshift({ value: 'All', label: 'All' });
		}
		setUniqueTriggers([...uniqueTriggersCopy]);
	}, [data, setUniqueTriggers]);

	const handleSubscriberSelect = (event: string) => {
		if (event) {
			const subscriber = delegates.filter((sub: DelegateObject) => {
				return sub.name === event;
			});
			setSelectedSubscriber({
				name: subscriber[0].name,
				delegate_user_id: subscriber[0].user_id,
				is_delegatee: subscriber[0].is_delegatee,
			});
		}
		setFilter({});
		setUniquePages([]);
		setUniqueTriggers([]);
	};

	const handleFilterSelection = useCallback(
		(attribute: string, value: string) => {
			let updatedFilter = { ...filter };
			if (value !== 'All') {
				if (filterPivot === undefined) {
					setFilterPivot(attribute);
					updatedFilter = {};
				}

				if (attribute === 'PAGE') {
					updatedFilter.workstream = value;
					if (filterPivot === attribute) {
						delete updatedFilter.arche_type;
					}
				} else if (attribute === 'TRIGGER') {
					updatedFilter.arche_type = value;
					if (filterPivot === attribute) {
						delete updatedFilter.workstream;
					}
				}
			} else {
				if (attribute === 'PAGE') {
					delete updatedFilter.workstream;
					if (filterPivot === attribute) {
						delete updatedFilter.arche_type;
					}
				} else if (attribute === 'TRIGGER') {
					delete updatedFilter.arche_type;
					if (filterPivot === attribute) {
						delete updatedFilter.workstream;
					}
				}
			}

			setFilter({ ...updatedFilter });
			setTableContentLoader(true);
		},
		[filter, filterPivot, setFilter, setTableContentLoader]
	);

	useEffect(() => {
		if (!filter.workstream) {
			updatePageFilter();
		}
		if (!filter.arche_type) {
			updateTriggerFilter();
		}

		if (isRefetchingNotifications && !tableContentLoader) {
			updatePageFilter();
			updateTriggerFilter();
		}
	}, [
		data,
		filter,
		isRefetchingNotifications,
		tableContentLoader,
		updatePageFilter,
		updateTriggerFilter,
	]);

	useEffect(() => {
		// in every refetch we want to set the original list to the new data
		// and check if there are any pending notifications.
		// No polling required if there's no data.
		if (data.length) {
			setOriginalList(data);
			// check if there are remaining pending notifications
			const isPending = data.some((notification) => notification.is_processing);
			// if false disable polling
			setEnablePolling(isPending);
		}
	}, [data, setEnablePolling, setOriginalList]);

	const delegatesList: Array<{ value: string; label: string }> =
		delegates?.map((subscriber: any) => {
			return {
				label:
					subscriber.name ===
					`${userFullname.split(', ')[1]} ${userFullname.split(', ')[0]}`
						? `${subscriber.name} (You)`
						: subscriber.name,
				value: subscriber.name,
			};
		}) ?? [];

	return (
		<>
			<div className="notifications-tab-content">
				<Flex className="notifications-table-controls" wrap="wrap">
					<Flex className="notifications-table-left-controls">
						<FilterComponent
							type={'SUBSCRIBER'}
							header={'Managing for'}
							optionsList={delegatesList}
							loading={tableLoader}
							handleSubscriberSelect={handleSubscriberSelect}
						/>
					</Flex>
					<FilterComponent
						header={'PAGE'}
						optionsList={uniquePages}
						filter={filter}
						loading={tableLoader}
						handleFilterSelection={handleFilterSelection}
						selectedValue={filter?.workstream ?? ''}
					/>
					<FilterComponent
						header={'TRIGGER'}
						optionsList={uniqueTriggers}
						filter={filter}
						loading={tableLoader}
						handleFilterSelection={handleFilterSelection}
						selectedValue={filter?.arche_type ?? ''}
					/>
				</Flex>
				{tableLoader || (tableContentLoader && !data.length) ? (
					<NotificationsLoader />
				) : (
					<NotificationsTable
						notificationsList={data}
						type={type}
						tableClassName="notifications-table"
						setNotificationData={setNotificationData}
						setOpen={setOpenPreview}
					/>
				)}
			</div>

			<PreviewModal
				open={openPreview}
				previewModalDismissed={previewModalDismissed}
				openValidationModal={openValidationModal}
				handleSubscription={handleSubscription}
				notification={notificationData}
				userName={selectedSubscriber?.name}
			/>
			<ValidationModal
				open={validationModalConfig.open}
				title={validationModalConfig.title}
				status={validationModalConfig.status}
				header={validationModalConfig.header}
				content={validationModalConfig.content}
				onModalDismiss={closeValidationModal}
				handleSubscription={handleSubscription}
				notification={notificationData}
				setOpenPreview={() => {
					setOpenPreview(true);
				}}
			/>
		</>
	);
};

export default ManageSubscriptionsTabContent;
