export function snakeToCamel(obj) {
	// Check if input is an object
	if (typeof obj !== 'object' || obj === null) {
		return obj; // Return as is if not an object
	}

	// Handle arrays separately
	if (Array.isArray(obj)) {
		// Map over each item in the array and recursively convert
		return obj.map((item) => snakeToCamel(item));
	}

	// Use Object.entries to get key-value pairs, then reduce them
	return Object.entries(obj).reduce((acc, [key, value]) => {
		// Convert snake_case key to camelCase
		const camelCaseKey = key.replace(/_([a-z])/g, (match, letter) =>
			letter.toUpperCase()
		);

		// Check if the value is an object to recursively convert it
		if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
			acc[camelCaseKey] = snakeToCamel(value); // Recursively convert nested object
		} else {
			acc[camelCaseKey] = value; // Assign the value directly
		}

		return acc;
	}, {});
}
