import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { fetchNotificationsList } from '../services/fetch-notifications';
import { AppContext } from '../state/app-context';
import { FetchNotificationResponse } from '../types';

export const useUpdateNotificationsList = (
	reactQueryKey: 'SubscriptionList' | 'NotificationList' | 'ManageList',
	setEnablePolling: (val: boolean) => void
) => {
	const { setOriginalList } = useContext(AppContext);
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: fetchNotificationsList,
		onSuccess: (updatedNotification: FetchNotificationResponse[]) => {
			// update original list
			setOriginalList(updatedNotification);

			// check if there are remaining pending notifications
			const isPending = updatedNotification.some(
				(notification) => notification.is_processing
			);
			// if false disable polling
			setEnablePolling(isPending);

			// update local data after sorting
			queryClient.setQueryData([reactQueryKey], () => updatedNotification);
		},
	});
};
