import { createContext } from 'react';
import {
	AdmissibleUserRoles,
	FetchNotificationResponse,
	SelectedSubscriber,
} from '../types';

export interface AppState {
	userFullname: string;
	userId: string;
	isRefetchingNotifications: boolean;
	setIsRefetchingNotifications: (val: boolean) => void;
	tableContentLoader: boolean;
	setTableContentLoader: (val: boolean) => void;
	selectedSubscriber: SelectedSubscriber;
	setSelectedSubscriber: (val: SelectedSubscriber) => void;
	queryDataKey: 'NotificationList' | 'SubscriptionList' | undefined;
	setQueryDataKey: (val: 'NotificationList' | 'SubscriptionList') => void;
	originalList: FetchNotificationResponse[];
	setOriginalList: (val: FetchNotificationResponse[]) => void;
	enablePolling: boolean;
	setEnablePolling: (val: boolean) => void;
	userRole: AdmissibleUserRoles;
	setUserRole: (val: AdmissibleUserRoles) => void;
}

export const AppContext = createContext<AppState>({
	userFullname:
		JSON.parse(localStorage?.getItem('sensing-okta-token') ?? '{}')?.idToken
			?.claims?.name ?? '',
	userId:
		JSON.parse(
			localStorage?.getItem('sensing-okta-token') ?? '{}'
		)?.idToken?.claims?.email.split('@')[0] ?? '',
	isRefetchingNotifications: false,
	setIsRefetchingNotifications: () => {},
	tableContentLoader: false,
	setTableContentLoader: () => {},
	selectedSubscriber: { name: '', is_delegatee: false, delegate_user_id: '' },
	setSelectedSubscriber: () => {},
	queryDataKey: undefined,
	setQueryDataKey: () => {},
	originalList: [],
	setOriginalList: () => {},
	enablePolling: false,
	setEnablePolling: () => {},
	userRole: undefined,
	setUserRole: () => {},
});
