import { fetchApi } from '../utils/services';

export const getDelegates = async (data: any) => {
	const response = await fetchApi({
		header: {},
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}\\subscribers/get_delegates`,
		data,
	});
	return response;
};
