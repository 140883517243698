import { BrowserRouter } from 'react-router-dom';
import Container from './pages/Container';
import './style/app.scss';
import { Flex } from '@opsdti-global-component-library/amgen-design-system';

const SubscriptionManagerApp: React.FC = () => {
	return (
		<Flex justify="center" className="subscriptions-wrapper">
			<div className="subscriptions-container">
				<BrowserRouter>
					<Container />
				</BrowserRouter>
			</div>
		</Flex>
	);
};

export default SubscriptionManagerApp;
