import { Table } from '@opsdti-global-component-library/amgen-design-system';

const ApprovalRecipientsTable = ({ header, data }) => {
	return (
		<div>
			<Table
				columns={header}
				dataSource={data}
				showSorterTooltip={false}
				onChange={() => {}}
				scroll={{
					x: '300px',
					y: data && data.length > 7 ? '245px' : undefined,
				}}
				size="small"
				style={{
					width: '434px',
				}}
			/>
		</div>
	);
};

export default ApprovalRecipientsTable;
