import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useUpdateNotificationsList } from '../../../hooks/use-update-notifications-list';
import ManageSubscriptionsTabContent from '../../../components/tabs-content/manage-subscriptions-tab-content';
import { fetchNotificationsList } from '../../../services/fetch-notifications';
import { AppContext } from '../../../state/app-context';
import {
	DelegateList,
	FilterSetting,
	SubscriptionType,
	UserType,
} from '../../../types';

interface AvailablePageContentProps {
	delegates: DelegateList;
}

const AvailablePageContent = ({ delegates }: AvailablePageContentProps) => {
	const {
		tableContentLoader,
		selectedSubscriber,
		enablePolling,
		setTableContentLoader,
		setIsRefetchingNotifications,
		setQueryDataKey,
		userRole,
		setEnablePolling,
	} = useContext(AppContext);
	const [filter, setFilter] = useState<FilterSetting>({});

	const {
		data: notificationList,
		isLoading,
		isRefetching,
		refetch: refetchNotificationsList,
	} = useQuery({
		queryKey: ['NotificationList'],
		queryFn: fetchNotificationsList.bind(this, {
			data: {
				is_admin: false,
				is_delegatee: selectedSubscriber.is_delegatee,
				delegate_user_id: selectedSubscriber.delegate_user_id,
				filters: tableContentLoader ? filter : {},
				subscribed_flag: false,
				delegate_user_type: UserType.USER,
			},
			type: SubscriptionType.UNSUBSCRIBED,
			getAD: true,
		}),
		enabled: false,
		refetchOnWindowFocus: false,
	});

	const fetchNotificationsListMutation = useUpdateNotificationsList(
		'NotificationList',
		setEnablePolling
	);

	useEffect(() => {
		setQueryDataKey('NotificationList');
	}, [setQueryDataKey]);

	useEffect(() => {
		refetchNotificationsList();
	}, [filter, selectedSubscriber, refetchNotificationsList]);

	useEffect(() => {
		const notificationListPolling = setInterval(() => {
			if (enablePolling) {
				fetchNotificationsListMutation.mutate({
					data: {
						is_admin: false,
						is_delegatee: selectedSubscriber.is_delegatee,
						delegate_user_id: selectedSubscriber.delegate_user_id,
						filters: filter,
						subscribed_flag: false,
						delegate_user_type: UserType.USER,
					},
					type: SubscriptionType.UNSUBSCRIBED,
					getAD: true,
				});
			}
		}, 10000);

		return () => clearInterval(notificationListPolling);
	}, [
		filter,
		selectedSubscriber,
		fetchNotificationsListMutation,
		enablePolling,
		userRole,
	]);

	useEffect(() => {
		setIsRefetchingNotifications(isRefetching);
		if (!isRefetching) {
			setTableContentLoader(false);
		}
	}, [
		// ,
		isRefetching,
		setIsRefetchingNotifications,
		setTableContentLoader,
	]);

	const isFetching = isLoading || isRefetching;
	// When it's not the first load, we want to apply the loader to the table
	const tableLoader = isFetching && !tableContentLoader;

	return (
		<ManageSubscriptionsTabContent
			data={notificationList ?? []}
			type={SubscriptionType.UNSUBSCRIBED}
			filter={filter}
			delegates={delegates}
			setFilter={setFilter}
			tableLoader={tableLoader}
		/>
	);
};

export default AvailablePageContent;
