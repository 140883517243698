import { Loader } from '@gitlab-rtsensing/component-library';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
const RequiredAuthComponent: React.FC = () => {
	const { oktaAuth, authState } = useOktaAuth();
	useEffect(() => {
		if (!authState) {
			return;
		}
		if (!authState?.isAuthenticated) {
			const originalUri = toRelativeUrl(
				window.location.href,
				window.location.origin
			);
			oktaAuth.setOriginalUri(originalUri);
			oktaAuth.signInWithRedirect();
		}
	}, [oktaAuth, authState?.isAuthenticated, authState]);
	if (!authState || !authState?.isAuthenticated) {
		return (
			<div className="notify-okta-loader">
				<Loader />
			</div>
		);
	}
	return <Outlet />;
};

export default RequiredAuthComponent;
