import { useOutletContext, useNavigate } from 'react-router-dom';
import { Tabs } from '@opsdti-global-component-library/amgen-design-system';
import { TabKeys, OutletContextType } from '../../../types';
import './index.scss';

type NotificationsTabsProps = {
	activeKey: TabKeys;
};

const NotificationsTabs = ({ activeKey }: NotificationsTabsProps) => {
	const navigate = useNavigate();

	const { allowedTabs } = useOutletContext<OutletContextType>();

	const handleTabClick = (tabKey) => {
		if (tabKey === TabKeys.SUBSCRIBED_TAB_KEY) {
			navigate('/subscriptions/');
		} else if (tabKey === TabKeys.AVAILABLE_TAB_KEY) {
			navigate('/subscriptions/available');
		} else if (tabKey === TabKeys.MANAGE_TAB_KEY) {
			navigate('/subscriptions/manage-users');
		} else if (tabKey === TabKeys.ACTIVITY_LOG_KEY) {
			navigate('/subscriptions/activity-log');
		} else {
			navigate('/subscriptions/request-log');
		}
	};

	return (
		<Tabs
			className="notifications-main-tabs"
			items={allowedTabs}
			defaultActiveKey={activeKey}
			onTabClick={(event) => {
				handleTabClick(event);
			}}
		/>
	);
};

export default NotificationsTabs;
