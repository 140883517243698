import { FetchAdUsersRequestData } from '../types';
import { fetchApi } from '../utils/services';

export const fetchAdUsers = async (data: FetchAdUsersRequestData) => {
	const response = await fetchApi({
		header: {},
		method: 'POST',
		url: `${process.env.REACT_APP_ADMIN_API_URL}\\userAudit/getADGroupUserList`,
		data,
	});
	return response?.data?.data;
};
