import { fetchApi } from '../utils/services';

import { ADUsersDataType } from '../types';

export const getADUsers = async (
	data: any,
	adGroup: string
): Promise<ADUsersDataType[]> => {
	const response = await fetchApi({
		header: {},
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}\\middleware/get/users_with_AD?ad_group=${adGroup}`,
		data,
	});
	return response?.data?.data;
};
