import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useNavigate } from 'react-router-dom';
import { NotificationOktaConfig } from '../../config/okta';

interface IAuthComponentProp {
	children: JSX.Element;
}

const AuthComponent: React.FC<IAuthComponentProp> = ({ children }) => {
	const history = useNavigate();
	const oktaAuthConfig = NotificationOktaConfig;
	const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
		history(toRelativeUrl(originalUri || '/', window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuthConfig} restoreOriginalUri={restoreOriginalUri}>
			{children}
		</Security>
	);
};
export default AuthComponent;
