import {
	StatusDefinitions,
	Text,
} from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';

type DeliveryStatusDefinitionProps = {
	status: 'success' | 'error' | 'missing' | '' | undefined;
	text: string;
	type?: 'primary' | 'secondary';
};

const DeliveryStatusDefinition = ({
	status,
	text,
	type = 'primary',
}: DeliveryStatusDefinitionProps) => {
	return status ? (
		<StatusDefinitions
			greenStatusText={status === 'success' ? text : undefined}
			redStatusText={status === 'error' ? text : undefined}
			missingDataText={status === 'missing' ? text : undefined}
			className={
				type === 'secondary' ? 'missing-delivery-status-definition' : undefined
			}
		/>
	) : (
		<Text>{text}</Text>
	);
};

export default DeliveryStatusDefinition;
