import { RequestLogModalData } from '../types';
import { fetchApi } from '../utils/services';

export const fetchRequestLogModalData = async (
	data: any,
	approvalToken: string
): Promise<RequestLogModalData> => {
	const response = await fetchApi({
		header: {},
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}\\requestlog/request_modal_view/${approvalToken}`,
		data,
	});
	return response?.data?.data[0];
};
