export const getOktaDetails = () => {
	const oktaToken: any = localStorage.getItem('sensing-okta-token');
	const oktaTokenObj = JSON.parse(oktaToken);
	if (oktaTokenObj?.accessToken !== undefined) {
		return oktaTokenObj;
	}
	return null;
};

export const getOktaToken = () => {
	const oktaTokenDetails = getOktaDetails();
	if (oktaTokenDetails !== null) {
		return oktaTokenDetails.accessToken.accessToken;
	}
	return '';
};
