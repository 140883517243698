import { fetchApi } from '../utils/services';

export const fetchActivityLogDropdowns = async (data: any) => {
	const response = await fetchApi({
		header: {},
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}\\activityLog/get_dropdown_values`,
		data,
	});
	return response?.data?.data;
};
