import {
	Flex,
	Search,
	Segmented,
} from '@opsdti-global-component-library/amgen-design-system';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useHandleSubscription } from '../../../hooks/use-handle-subscription';
import { useValidationModal } from '../../../hooks/use-validation-modal';
import { getTableEntries } from '../../../services/get-table-entries';
import { AppContext } from '../../../state/app-context';
import {
	FetchNotificationResponse,
	SubscriptionAction,
	UserAdGroupToggle,
	UserType,
} from '../../../types';
import ManageUsersModal from '../../modals/manage-users-modal';
import PreviewModal from '../../modals/preview-modal';
import ValidationModal from '../../modals/validation-modal';
import NotificationsLoader from '../../reusable/notifications-loader';
import ManageUsersTable from '../../tables/manage-users-table';
import '../index.scss';

const ManageUsersTabContent = () => {
	const { setTableContentLoader, userFullname } = useContext(AppContext);
	const [userData, setUserData] = useState<any>([]);
	const [manageUserData, setManageUserData] = useState({
		active_subscriptions: '',
		full_name: '',
		userid: '',
	});
	const [selectedTab, setSelectedTab] = useState<string | number>(
		UserAdGroupToggle.USER
	);
	const [userAction, setUserAction] = useState<SubscriptionAction | undefined>(
		undefined
	);
	const [openManageUserModal, setOpenManageUserModal] =
		useState<boolean>(false);
	const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
	const [searchInput, setSearchInput] = useState<string>('');
	const [notificationData, setNotificationData] = useState<
		FetchNotificationResponse | undefined
	>();
	const [manageLoader, setManageLoader] = useState(true);
	const { validationModalConfig, openValidationModal, closeValidationModal } =
		useValidationModal();

	const updateUserModalState = useCallback((state: boolean) => {
		setOpenManageUserModal(state);
	}, []);

	const previewModalDismissed = useCallback(() => {
		setOpenPreviewModal(false);
	}, []);

	const openUnsubscribeModal = () => {
		const subscriberName = manageUserData?.full_name
			.split(', ')
			.reverse()
			.join(' ');

		openValidationModal(
			SubscriptionAction.DELETE_SUBSCRIBER,
			notificationData?.workstream,
			subscriberName,
			notificationData?.name
		);
	};

	// Button click handler for EDIT and UNSUBSCRIBE buttons in the Manage Users Modal
	const handleButtonClick = (action: SubscriptionAction, selectedRow) => {
		setOpenManageUserModal(false);
		setNotificationData(selectedRow);
		if (action === SubscriptionAction.UPDATE_SUBSCRIBER) {
			setOpenPreviewModal(true);
		} else if (action === SubscriptionAction.DELETE_SUBSCRIBER) {
			openUnsubscribeModal();
		}
		setUserAction(action);
	};

	const handleChange = (event) => {
		setSearchInput(event?.target?.value);
	};

	// function to filter the Manage Users table based on the user's search input
	const SearchData = (entries: any, searchType: string | number) => {
		const filteredData = entries.filter((entry) => {
			if (searchInput && searchInput.trim()) {
				if (searchType === UserAdGroupToggle.USER) {
					return (
						entry?.full_name
							.toLowerCase()
							.includes(searchInput.toLowerCase()) ||
						(String(entry?.userid) + '@amgen.com')
							.toLowerCase()
							.includes(searchInput.toLowerCase())
					);
				} else {
					return entry?.full_name
						.toLowerCase()
						.includes(searchInput.toLowerCase());
				}
			} else {
				return entries;
			}
		});
		return filteredData;
	};
	const filteredData = userData.length > 0 && SearchData(userData, selectedTab);

	//
	const mutation = useHandleSubscription('ManageList');

	const handleSubscription = (
		action: SubscriptionAction,
		notificationUpdates: any
	) => {
		// Before API call initialize lazy loading in the Table component.
		// tableContentLoader is a boolean value which if true shows loader in Table component.
		setTableContentLoader(true);
		previewModalDismissed();

		const updatedNotificationData: FetchNotificationResponse = {
			...notificationUpdates,
		};
		mutation.mutate({
			data: {
				is_admin: true,
				user_full_name: userFullname,
				notifications: [
					{
						notification_id: updatedNotificationData.id,
						user_frequency: updatedNotificationData.frequency,
						delivery_channel: updatedNotificationData.selected_channels ?? [],
						is_enabled: updatedNotificationData.is_enabled ?? false,
						action_type: action,
					},
				],
				is_delegatee: true,
				delegate_user_id: manageUserData.userid,
				delegate_type:
					selectedTab === UserAdGroupToggle.ADGROUP
						? UserType.AD
						: UserType.USER,
			},
			notification: updatedNotificationData,
		});
	};

	// hit the get_table_entries to get either the users or AD groups lists and their active subscriptions.
	// API is hit whenever the USER/AD GROUP toggle is switched.
	// Lazy loading is implemented.
	useEffect(() => {
		let tableEntriesPolling: NodeJS.Timeout;

		const fetchTableEntries = async () => {
			getTableEntries(selectedTab === UserAdGroupToggle.USER ? true : false)
				.then((response) => {
					const data = response?.data?.data;
					setUserData(data);
				})
				.catch((error) => console.log(error))
				.finally(() => setManageLoader(false));

			tableEntriesPolling = setInterval(() => {
				getTableEntries(selectedTab === UserAdGroupToggle.USER ? true : false)
					.then((response) => {
						const data = response?.data?.data;
						setUserData(data);
					})
					.catch((error) => console.log(error));
			}, 10000);
		};

		fetchTableEntries();

		return () => clearInterval(tableEntriesPolling);
	}, [selectedTab]);

	useEffect(() => {
		const appRoot = document && document?.getElementsByTagName('html')[0];
		if (!openManageUserModal) {
			appRoot.style.overflowY = '';
		}
	}, [openManageUserModal]);

	return (
		<>
			<div className="notifications-tab-content">
				<Flex
					className="notifications-table-controls"
					justify="space-between"
					wrap="wrap"
				>
					<Segmented
						onChange={(value: string | number) => {
							setSelectedTab(value);
							setSearchInput('');
							setManageLoader(true);
						}}
						options={[UserAdGroupToggle.USER, UserAdGroupToggle.ADGROUP]}
						disabled={manageLoader}
					/>
					<Search
						className="notifications-manage-search"
						onChange={handleChange}
						onSearch={() => {}}
						placeholder={
							!searchInput.trim()
								? `Search ${
										selectedTab === UserAdGroupToggle.USER ? 'by' : 'for'
								  } name${
										selectedTab === UserAdGroupToggle.USER ? ' or e-mail' : ''
								  }`
								: undefined
						}
						value={searchInput}
						disabled={manageLoader}
					/>
				</Flex>
				{manageLoader ? (
					<NotificationsLoader />
				) : (
					<ManageUsersTable
						tableClassName="notifications-table"
						data={filteredData}
						selectedTab={selectedTab}
						updateUserModalState={updateUserModalState}
						setManageUserData={setManageUserData}
					/>
				)}
			</div>

			<ManageUsersModal
				open={openManageUserModal}
				closeModal={() => {
					setOpenManageUserModal(false);
				}}
				selectedTab={selectedTab}
				data={manageUserData}
				handleButtonClick={handleButtonClick}
			/>
			<PreviewModal
				open={openPreviewModal}
				previewModalDismissed={previewModalDismissed}
				openValidationModal={openValidationModal}
				handleSubscription={handleSubscription}
				notification={notificationData}
				setOpenManageUserModal={setOpenManageUserModal}
				userName={manageUserData.full_name}
			/>
			<ValidationModal
				open={validationModalConfig.open}
				title={validationModalConfig.title}
				status={validationModalConfig.status}
				header={validationModalConfig.header}
				content={validationModalConfig.content}
				onModalDismiss={closeValidationModal}
				handleSubscription={handleSubscription}
				notification={notificationData}
				setOpenPreview={
					userAction && userAction === SubscriptionAction.UPDATE_SUBSCRIBER
						? () => {
								setOpenPreviewModal(true);
						  }
						: () => {
								setOpenManageUserModal(true);
						  }
				}
			/>
		</>
	);
};

export default ManageUsersTabContent;
