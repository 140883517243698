import {
	Flex,
	Label,
	Select,
	StatusIcon,
	Text,
	theme,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext } from 'react';
import { deliveryStatusMapping } from '../../constants';
import { AppContext } from '../../state/app-context';
import { FilterSetting } from '../../types';
import './index.scss';

interface FilterComponentProps {
	type?: string;
	header: string;
	optionsList: Array<{ value: string; label: string }>;
	filter?: FilterSetting;
	loading: boolean;
	selectedValue?: string;
	handleFilterSelection?: (attribute: string, event: string) => void;
	handleSubscriberSelect?: (event: string) => void;
}

const FilterComponent = ({
	type,
	header,
	optionsList,
	filter,
	loading,
	selectedValue,
	handleFilterSelection,
	handleSubscriberSelect,
}: FilterComponentProps) => {
	const {
		isRefetchingNotifications,
		tableContentLoader,
		selectedSubscriber,
		setTableContentLoader,
	} = useContext(AppContext);

	const { token } = theme;

	const disableFilter =
		isRefetchingNotifications || tableContentLoader || loading;
	const disableOptions =
		type !== 'SUBSCRIBER' &&
		disableFilter &&
		filter &&
		!Object.keys(filter).length;

	return (
		optionsList && (
			<Flex
				align="center"
				gap={8}
				className={`notifications-filter ${
					type === 'SUBSCRIBER'
						? 'notifications-subscriptions-filter'
						: undefined
				}`}
			>
				{type !== 'SUBSCRIBER' ? (
					<Label>{header}</Label>
				) : (
					<Text
						className="notifications-delegates-filter-header"
						style={{ color: token?.colorTextLabel }}
					>
						{header}
					</Text>
				)}
				<Select
					className="notifications-filter-select"
					onChange={(value) => {
						if (type !== 'SUBSCRIBER' && handleFilterSelection) {
							handleFilterSelection(header, value);
						} else if (type === 'SUBSCRIBER' && handleSubscriberSelect) {
							handleSubscriberSelect(value);
						}
						setTableContentLoader(true);
					}}
					options={type !== 'STATUS' ? optionsList : undefined}
					defaultValue={optionsList[0]?.value ?? ''}
					disabled={disableFilter || disableOptions}
					loading={disableFilter || disableOptions}
					value={
						type === 'SUBSCRIBER'
							? selectedSubscriber.name
							: selectedValue?.length && selectedValue.length > 0
							? selectedValue
							: optionsList[0]?.value ?? ''
					}
				>
					{type === 'STATUS'
						? optionsList.map((option) => {
								return (
									<Select
										key={option.value}
										value={option.value}
										style={{ flexGrow: '1' }}
									>
										<Flex gap={5} align="center">
											<StatusIcon
												status={
													deliveryStatusMapping[option?.label] as
														| 'warning'
														| 'error'
														| 'success'
														| 'missing'
														| 'star'
												}
												style={{
													width: '16px',
													height: '16px',
												}}
											/>
											{option?.label}
										</Flex>
									</Select>
								);
						  })
						: undefined}
				</Select>
			</Flex>
		)
	);
};

export default FilterComponent;
