import { fetchApi } from '../utils/services';

export const getTabAuth = async (data: any) => {
	const response = await fetchApi({
		header: {},
		method: 'POST',
		url: `${process.env.REACT_APP_AUTH_LINK}`,
		data,
	});
	return response;
};
