import { QueryClient, QueryClientProvider } from 'react-query';
import NotificationsRoutes from '../components/notifications-routes';

const Container: React.FC = () => {
	const queryClient = new QueryClient();
	return (
		<>
			<QueryClientProvider client={queryClient}>
				<NotificationsRoutes />
			</QueryClientProvider>
		</>
	);
};

export default Container;
