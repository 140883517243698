import { useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { manageSubscriptions } from '../services/manage-subscriptions';
import { AppContext } from '../state/app-context';
import { FetchNotificationResponse } from '../types';

export const useHandleSubscription = (reactQueryKey: string) => {
	const { enablePolling, setTableContentLoader, setEnablePolling } =
		useContext(AppContext);

	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: manageSubscriptions,
		onSuccess: (updatedNotification: FetchNotificationResponse) => {
			setTableContentLoader(false);
			if (!enablePolling) {
				setEnablePolling(true);
			}

			// update local data
			queryClient.setQueryData(
				[reactQueryKey],
				(
					oldList: FetchNotificationResponse[] | undefined
				): FetchNotificationResponse[] => {
					const updatedData = [...(oldList ?? [])];
					const index =
						updatedData?.findIndex(
							(elem) => elem.id === updatedNotification.id
						) ?? -1;
					if (index > -1 && updatedData) {
						updatedData[index] = {
							...updatedNotification,
							is_processing: true,
						};
					}
					return updatedData;
				}
			);
		},
	});
};
