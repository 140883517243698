import { Loader } from '@gitlab-rtsensing/component-library';
import {
	Flex,
	Skeleton,
	Spin,
} from '@opsdti-global-component-library/amgen-design-system';

type NotificationsLoaderProps = {
	type?: 'skeleton' | 'spin' | 'loader';
	style?: React.CSSProperties;
};

const NotificationsLoader = ({
	type = 'loader',
	...props
}: NotificationsLoaderProps) => {
	return (
		<Flex
			justify="center"
			align="center"
			flex={1}
			className="subscriptions-loader"
			{...props}
		>
			{type === 'skeleton' ? (
				<Skeleton />
			) : type === 'spin' ? (
				<Spin />
			) : (
				<Loader />
			)}
		</Flex>
	);
};

export default NotificationsLoader;
