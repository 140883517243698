export const getChannelLabel = (channels: string[]) => {
	return channels
		.map((channel) => {
			if (channel.includes('teams')) {
				return 'Teams';
			} else if (channel.includes('email')) {
				return 'Email';
			} else {
				return 'SMS';
			}
		})
		.sort()
		.join(', ');
};
