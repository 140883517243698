import { fetchApi } from '../utils/services';

export const getTableEntries = async (toggleValue: boolean) => {
	const response = await fetchApi({
		header: {},
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}/subscribers/get_table_entries?user_toggle=${toggleValue}`,
	});
	return response;
};
