import {
	ArrowLeftIcon,
	Button,
} from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';

type NotificationsBackButtonProps = {
	text: string;
	onClick: () => void;
	style?: React.CSSProperties;
};

const NotificationsBackButton = ({
	text,
	onClick,
	...props
}: NotificationsBackButtonProps) => {
	return (
		<Button
			className="notifications-back-button"
			text={text}
			icon={
				<ArrowLeftIcon
					color="primary"
					height="100%"
					width={14}
					style={{ marginRight: '4px' }}
				/>
			}
			onClick={onClick}
			type="link"
			{...props}
		/>
	);
};

export default NotificationsBackButton;
