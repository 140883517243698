const sortData = (a, b, sortOrder) => {
	if (a && b) {
		return a.toLowerCase() === b.toLowerCase()
			? 0
			: a.toLowerCase() < b.toLowerCase()
			? -1
			: 1;
	} else if (!a && b) {
		return sortOrder === 'descend' ? -1 : 1;
	} else if (a && !b) {
		return sortOrder === 'descend' ? 1 : -1;
	} else {
		return 0;
	}
};

export default sortData;
