import axios, { AxiosResponse } from 'axios';
import {
	FetchAdUsersRequestData,
	ManageSubscriptionsRequestData,
	NotificationsListRequestData,
	SubscriberTableEntriesRequestData,
} from '../types';
import { getOktaToken } from './okta';

interface ConfigureDataProp {
	url: string;
	method: string;
	header: object;
	data?:
		| NotificationsListRequestData
		| ManageSubscriptionsRequestData
		| FetchAdUsersRequestData
		| SubscriberTableEntriesRequestData;
}

export const fetchApi: (
	configureData: ConfigureDataProp
) => Promise<AxiosResponse<any>> = async (configureData) => {
	const token: string = getOktaToken();
	try {
		return await axios({
			...configureData,
			headers: {
				authorization: `Bearer ${token}`,
			},
		});
	} catch (e: any) {
		throw new Error(e?.message ?? '');
	}
};
