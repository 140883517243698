import {
	FetchADNotificationsResponse,
	FetchNotificationResponse,
	FetchNotificationsRequest,
} from '../types';
import { consolidateNotificationsRecords } from '../utils/consolidate';
import { fetchApi } from '../utils/services';

/**
 * This is an async function to fetch the list of auhtorized notifications and AD group subscriptions.
 * @param data is the payload object which includes the request body for the API calls and the .
 * @returns It returns the consolidated list of notifications.
 */
export const fetchNotificationsList = async (
	data: FetchNotificationsRequest
): Promise<FetchNotificationResponse[]> => {
	// fetch the user's available and authorized notifications
	const fetchNotifications = fetchApi({
		header: {},
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}\\subscribers/fetch_notifications`,
		data: data?.data,
	});

	let response: void | FetchNotificationResponse[];

	if (data?.getAD) {
		// fetch the user's AD group notifications
		const fetchAdNotifications = fetchApi({
			header: {},
			method: 'POST',
			url: `${process.env.REACT_APP_API_URL}\\subscribers/fetch_ad_notifications`,
			data: data?.data,
		});
		// run both fetchApi calls in parallel
		response = await Promise.all([fetchNotifications, fetchAdNotifications])
			.then((responses) => {
				const [fetchNotificationsResponse, fetchAdNotificationsResponse] =
					responses;

				// consolidate the ad notifications' information into the user's available notifications
				const consolidatedResponse = consolidateNotificationsRecords(
					fetchNotificationsResponse?.data?.data as FetchNotificationResponse[],
					fetchAdNotificationsResponse?.data
						?.data as FetchADNotificationsResponse[],
					data.type
				);

				return consolidatedResponse;
			})
			.catch((error) => console.log(error));
	} else {
		response = await fetchNotifications
			.then((responses) => responses?.data?.data)
			.catch((error) => console.log(error));
	}

	return response ?? ([] as FetchNotificationResponse[]);
};
