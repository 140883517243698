import { fetchApi } from '../utils/services';

export const manageNotificationRequest = async (
	action: 'approve' | 'deny',
	approverToken: string
) => {
	const response = await fetchApi({
		header: {},
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}\\approval/${action}/${approverToken}`,
	});
	return response?.data?.data;
};
