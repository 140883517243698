import {
	Flex,
	Tag,
	Text,
	theme,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext } from 'react';
import { AppContext } from '../../../../state/app-context';

interface PreviewModalSubheaderProps {
	manageUserName: string;
}

const PreviewModalSubheader = ({
	manageUserName,
}: PreviewModalSubheaderProps) => {
	const { selectedSubscriber } = useContext(AppContext);

	const { token } = theme;

	return (
		<Flex vertical gap={8}>
			<Text strong>
				<Flex align="center" gap={8}>
					Editing Subscription for{' '}
					<Tag
						text={
							manageUserName
								? manageUserName.split(', ').reverse().join(' ')
								: selectedSubscriber.name
						}
						style={{ pointerEvents: 'none' }}
					/>
				</Flex>
			</Text>
			<Text style={{ color: token?.colorTextLabel }}>
				Example of what the notifications will look like.
			</Text>
		</Flex>
	);
};

export default PreviewModalSubheader;
