import {
	FetchNotificationResponse,
	ManageSubscriptionsRequest,
} from '../types';
import { fetchApi } from '../utils/services';

export const manageSubscriptions = async (
	data: ManageSubscriptionsRequest
): Promise<FetchNotificationResponse> => {
	await fetchApi({
		header: {},
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}\\subscribers/request_widget_subscription`,
		data: data.data,
	});
	return data.notification;
};
