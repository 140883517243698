import { RequestLogTableResponse } from '../types';
import { fetchApi } from '../utils/services';

export const fetchRequestLogTableData = async (
	data: any
): Promise<RequestLogTableResponse> => {
	const response = await fetchApi({
		header: {},
		method: 'GET',
		url: `${process.env.REACT_APP_API_URL}\\requestlog/request_log_table_data`,
		data,
	});
	return response?.data?.data;
};
