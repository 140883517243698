import {
	Flex,
	Title,
	Text,
	InfoIcon,
} from '@opsdti-global-component-library/amgen-design-system';

type UnexpectedErrorProps = {
	errorDetails: string;
	style?: React.CSSProperties;
};

const UnexpectedError = ({ errorDetails, ...props }: UnexpectedErrorProps) => {
	return (
		<Flex align="center" justify="center" gap={16} vertical {...props}>
			<InfoIcon color="primary" height={40} width={40} />
			<Flex align="center" justify="center" gap={8} vertical>
				<Title level={2}>Unexpected Error</Title>
				<Text style={{ textAlign: 'center' }}>
					Unable to load data at this time. Please contact the notification team
					to alert them of this error.
				</Text>
			</Flex>
			<Text type='secondary'>{`Error details: ${errorDetails}`}</Text>
		</Flex>
	);
};

export default UnexpectedError;
