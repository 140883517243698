import { FetchNotificationResponse } from '../types';

export const getUniqueValues = (
	data: Array<FetchNotificationResponse>,
	key: string
) => {
	const uniqueValues = new Set();
	data.forEach((item) => {
		uniqueValues.add(item[key]);
	});
	return Array.from(uniqueValues) as string[];
};
