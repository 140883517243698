import {
	Button,
	Flex,
	Modal,
	Paragraph,
	StatusIcon,
	Title,
} from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../../state/app-context';
import {
	FetchNotificationResponse,
	SubscriptionAction,
	ValidationStatusType,
} from '../../../types';
import NotificationsLoader from '../../reusable/notifications-loader';
import './index.scss';
interface ValidationModalProps {
	title: string;
	status: ValidationStatusType;
	header: string;
	content: string;
	open: boolean;
	notification?: FetchNotificationResponse | undefined;
	isRequestLog?: boolean;
	handleSubscription?: (
		action: SubscriptionAction,
		notificationUpdates: any
	) => void;
	onModalDismiss: (resetRoute?: boolean, status?: ValidationStatusType) => void;
	setOpenPreview?: () => void;
	setOpenConfirm?: (action: SubscriptionAction) => void;
}

type StatusIconType = 'success' | 'warning' | 'error' | 'missing' | 'star';

const ValidationModal = ({
	title,
	status,
	header,
	content,
	open,
	notification,
	isRequestLog,
	onModalDismiss,
	handleSubscription,
	setOpenPreview,
	setOpenConfirm,
}: ValidationModalProps) => {
	const { tableContentLoader } = useContext(AppContext);

	const validationModalFooter = (
		<Flex
			align="center"
			justify="center"
			className="subscriptions-validation-modal-footer"
			gap={8}
		>
			{status === 'WARNING' && setOpenPreview ? (
				<>
					<Button
						className=""
						text="CANCEL"
						onClick={() => {
							if (isRequestLog) {
								onModalDismiss(false);
							} else {
								onModalDismiss();
							}
							setOpenPreview();
						}}
						type="link"
						style={{ flex: '1' }}
					/>
					{title === 'Re-send Notification' && setOpenConfirm ? (
						<Button
							className="subscriptions-validation-modal-unsubscribe-button"
							text={
								header === 'Deny and re-send this notification?'
									? 'DENY AND RESEND'
									: 'RESEND'
							}
							onClick={() => {
								onModalDismiss();
								setOpenConfirm(SubscriptionAction?.RESEND_REQUEST);
							}}
							type="primary"
							style={{ flex: '1' }}
						/>
					) : handleSubscription ? (
						<Button
							className="subscriptions-validation-modal-unsubscribe-button"
							text="UNSUBSCRIBE"
							onClick={() => {
								handleSubscription(SubscriptionAction.DELETE_SUBSCRIBER, {
									...notification,
									is_subscribed: false,
								});
								onModalDismiss();
							}}
							type="primary"
							style={{ flex: '1' }}
						/>
					) : undefined}
				</>
			) : (
				<Button
					className=""
					text={'CLOSE'}
					onClick={() => {
						if (isRequestLog) {
							onModalDismiss(undefined, status);
						} else {
							onModalDismiss();
						}
					}}
					type="primary"
					style={{ flex: '1' }}
				/>
			)}
		</Flex>
	);

	useEffect(() => {
		if (open) {
			if (!tableContentLoader && status === 'UPDATE') {
				onModalDismiss();
			}
		}
	}, [onModalDismiss, status, tableContentLoader, open]);

	return !notification && !isRequestLog ? null : (
		<Modal
			open={open}
			onCancel={() => {
				if (!tableContentLoader) {
					if (isRequestLog) {
						onModalDismiss(undefined, status);
					} else {
						onModalDismiss();
					}
				}
			}}
			className="subscriptions-validation-modal"
			classNames={{ body: 'subscriptions-validation-modal-body-content' }}
			title={title}
			footer={
				(tableContentLoader && status !== 'WARNING') || status === 'UPDATE'
					? null
					: validationModalFooter
			}
		>
			{tableContentLoader && status !== 'WARNING' ? (
				<NotificationsLoader type="spin" />
			) : status !== 'UPDATE' ? (
				<>
					<StatusIcon
						status={status.toLowerCase() as StatusIconType}
						size="large"
					/>
					<Title
						level={3}
						className="subscriptions-validation-modal-content-header"
					>
						{header}
					</Title>
					{content && (
						<Paragraph className="subscriptions-validation-modal-content-description">
							{content}
						</Paragraph>
					)}
				</>
			) : (
				<></>
			)}
		</Modal>
	);
};

export default ValidationModal;
