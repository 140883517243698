import {
	FetchADNotificationsResponse,
	FetchNotificationResponse,
	SubscriptionType,
} from '../types';

/**
 * This utility function consolidates the AD group notifications and the user's available notifications into a single notifications record.
 */
export const consolidateNotificationsRecords = (
	userNotifications: FetchNotificationResponse[],
	adNotifications: FetchADNotificationsResponse[],
	subscriptionType: SubscriptionType
): FetchNotificationResponse[] => {
	const consolidatedNotifications: FetchNotificationResponse[] = [];
	const adNotificationIds = adNotifications.map(
		(notification) => notification.notification_id
	);
	const adNotificationIsProcessing = adNotifications.map(
		(notification) => notification.is_processing
	);
	const adNotificationsSubscribedChannels = adNotifications.map(
		(notification) => notification.subscribed_channels
	);
	const adNotificationsGroups = adNotifications.map(
		(notification) => notification.ad_group
	);

	userNotifications.forEach((notification) => {
		const index = adNotificationIds.indexOf(notification.id);
		if (index !== -1) {
			consolidatedNotifications.push({
				...notification,
				is_subscribed: true,
				is_processing: adNotificationIsProcessing[index],
				is_ad_group: true,
				subscribed_channels: adNotificationsSubscribedChannels[index],
				ad_group: adNotificationsGroups[index],
			});
		} else {
			if (
				subscriptionType === SubscriptionType.UNSUBSCRIBED ||
				notification.is_subscribed
			) {
				consolidatedNotifications.push({
					...notification,
					is_ad_group: false,
					ad_group: '',
				});
			}
		}
	});

	return consolidatedNotifications;
};
