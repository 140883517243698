import { fetchApi } from '../utils/services';

export const fetchActivityLogSummaryData = async (data: any) => {
	const response = await fetchApi({
		header: {},
		method: 'POST',
		url: `${process.env.REACT_APP_API_URL}\\activitylogsummaryuirouter/notifications/activity_log_summary_ui`,
		data,
	});
	return response?.data?.data?.ai_summary;
};
