export function convertUTCToPST(utcDateString: string) {
	// Create a Date object from the UTC string
	const formattedUTCString = utcDateString.replace('+00:00', '') + 'Z';
	const date = new Date(formattedUTCString);

	// Convert the UTC date to PST (UTC-8 or UTC-7 depending on DST)
	const pstDate = new Date(
		date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })
	);

	// Extract the components for formatting
	const month = String(pstDate.getMonth() + 1).padStart(2, '0');
	const day = String(pstDate.getDate()).padStart(2, '0');
	const year = String(pstDate.getFullYear()).slice(-2);
	let hours = pstDate.getHours();
	const minutes = String(pstDate.getMinutes()).padStart(2, '0');
	const period = hours >= 12 ? 'PM' : 'AM';

	// Convert to 12-hour format
	hours = hours % 12 || 12;

	// Construct the formatted date string
	const formattedDate = `${month}/${day}/${year} ${hours}:${minutes}${period} PST`;

	return formattedDate;
}
