import { LoginCallback } from '@okta/okta-react';
import { ThemeProvider } from '@opsdti-global-component-library/amgen-design-system';
import { useContext, useState, useEffect } from 'react';
import {
	Navigate,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import NotificationsRoot from '../../pages';
import ActivityLogPage from '../../pages/activity-log-page';
import AvailablePage from '../../pages/available-page';
import ManagePage from '../../pages/manage-page';
import RequestLogPage from '../../pages/request-log-page';
import SubscriptionsPage from '../../pages/subscriptions-page';
import UnauthorizedPage from '../../pages/unauthorized-page';
import { AppContext } from '../../state/app-context';
import {
	AdmissibleUserRoles,
	FetchNotificationResponse,
	SelectedSubscriber,
} from '../../types';
import AuthComponent from '../auth-component';
import RequiredAuthComponent from '../auth-component/required-auth';
import PageLayout from '../page-layout';
import { notificationsTabs } from '../../constants';

const NotificationsRoutes: React.FC = () => {
	const { userFullname, userId } = useContext(AppContext);
	const [isRefetchingNotifications, setIsRefetchingNotifications] =
		useState<boolean>(false);
	const [tableContentLoader, setTableContentLoader] = useState<boolean>(false);
	const [selectedSubscriber, setSelectedSubscriber] =
		useState<SelectedSubscriber>({
			name: `${userFullname.split(', ')[1]} ${userFullname.split(', ')[0]}`,
			is_delegatee: false,
			delegate_user_id: '',
		});
	const [queryDataKey, setQueryDataKey] = useState<
		'NotificationList' | 'SubscriptionList' | undefined
	>(undefined);
	const [originalList, setOriginalList] = useState<FetchNotificationResponse[]>(
		[]
	);
	const [enablePolling, setEnablePolling] = useState<boolean>(false);
	const [userRole, setUserRole] = useState<AdmissibleUserRoles>(undefined);

	const themeService = null;

	const location = useLocation();
	const navigate = useNavigate();

	// this useffect manages all trailing slashes in valid params in the notifications page
	// it also manages redirection from any ivalid param after the /subscriptions/ to /subscriptions
	useEffect(() => {
		const paramsList = location.pathname.split('/');
		if (paramsList.length > 2) {
			if (
				paramsList[2] === notificationsTabs.SUBSCRIBED || // this comparison is required to track the trail slash for the subscriptions path
				!Object.values(notificationsTabs).includes(paramsList[2])
			) {
				// if the param coming after /subscriptions/ is not within the allowed subscriptions params, redirect the user to /subscriptions
				navigate('/subscriptions');
			} else {
				if (paramsList.length > 3 && paramsList[3] === '') {
					// for any trailing slash redirect the user to the path without the slash
					navigate('/subscriptions/' + paramsList[2]);
				}
			}
		}
	}, [location, navigate]);

	return (
		<AuthComponent>
			<ThemeProvider themeService={themeService}>
				<PageLayout>
					<AppContext.Provider
						value={{
							userFullname: userFullname,
							userId: userId,
							isRefetchingNotifications: isRefetchingNotifications,
							setIsRefetchingNotifications,
							tableContentLoader: tableContentLoader,
							setTableContentLoader: setTableContentLoader,
							selectedSubscriber: selectedSubscriber,
							setSelectedSubscriber,
							queryDataKey: queryDataKey,
							setQueryDataKey: setQueryDataKey,
							originalList: originalList,
							setOriginalList,
							enablePolling: enablePolling,
							setEnablePolling,
							userRole: userRole,
							setUserRole,
						}}
					>
						<Routes>
							<Route path="/" element={<RequiredAuthComponent />}>
								<Route
									path=""
									element={<Navigate to="/subscriptions" />}
								></Route>
								<Route path="/subscriptions" element={<NotificationsRoot />}>
									<Route index element={<SubscriptionsPage />} />
									<Route path="available">
										<Route index element={<AvailablePage />} />
										<Route
											path="*"
											element={
												<Navigate to="/subscriptions/available" replace />
											}
										/>
									</Route>
									<Route path="manage-users">
										<Route index element={<ManagePage />} />
										<Route
											path="*"
											element={
												<Navigate to="/subscriptions/manage-users" replace />
											}
										/>
									</Route>
									<Route path="request-log/:requestId?">
										<Route index element={<RequestLogPage />} />
									</Route>
									<Route path="unauthorized">
										<Route index element={<UnauthorizedPage />} />
										<Route
											path="*"
											element={
												<Navigate to="/subscriptions/unauthorized" replace />
											}
										/>
									</Route>
									<Route path="activity-log" element={<ActivityLogPage />} />
								</Route>
							</Route>
							<Route path="/implicit/callback" element={<LoginCallback />} />
						</Routes>
					</AppContext.Provider>
				</PageLayout>
			</ThemeProvider>
		</AuthComponent>
	);
};
export default NotificationsRoutes;
